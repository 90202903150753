define("discourse/plugins/email-all/discourse/controllers/admin-plugins-email-all", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    actions: {
      send() {
        (0, _ajax.ajax)('/admin/plugins/email-all', {
          type: 'POST',
          data: {
            subject: this.get('subject'),
            body: this.get('body')
          }
        }).then(() => {
          alert('Success! Jobs enqueued, emails being sent');
        }).catch(() => {
          alert('Error');
        });
      }
    }
  });
});